import React from "react";
import SEO from "@components/seo";
import Layout from "@components/layout";
import Footer from "@components/footers/dark";
import MenuBar from "@components/menuBar/MenuBar";
import TabBar from "./TabBar";

function Digital() {
  return (
    <>
      <SEO title="Digital Terms and Conditions" />
      <MenuBar/>
      <Layout>
        <TabBar tabKey={"digital"}/>
        <Content />
      </Layout>
      <Footer/>
    </>
  )
}

const Content = () => (
  <div className="relative py-16 bg-white overflow-hidden">
    <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
      </div>
    </div>
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="text-lg max-w-prose mx-auto">
        <h1>
          <span className="block text-base text-center text-highlight font-semibold tracking-wide uppercase">Waivpay</span>
          <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Digital Gift Card Terms &amp; Conditions
          </span>
        </h1>
      </div>

      <div className="mt-12 prose prose-stone text-gray-500 prose-lg max-w-prose mx-auto leading-8">
        <h2>Section 1 – General</h2>
        <h3>Definitions</h3>
        <p>For the purpose of these terms:</p>
        <ul className="list-outside">
          <li>'Card' means Gift Card issued by WAIVPAY LTD ABN 29 630 927 105 & GiVV Technologies Ltd ABN 91 143 010 048 (we, us, our)</li>
          <li>'Card Number' means the number that appears on your Card.</li>
          <li>'Device' means the mobile phone, wearable device or web browser that allows you store the Card or Card details.</li>
          <li>'Gift Card' or 'Card' means the Waivpay Pays-enabled Digital Gift Card issued by Waivpay and distributed by Waivpay.</li>
          <li>'Us', 'We', 'Our' means Waivpay</li>
          <li>'You' are the Card purchaser or user.</li>
        </ul>

        <h3>Background</h3>
        <p>Your Card ("Card") is issued by Waivpay. In these terms and conditions, 'you' are the Card purchaser or user and by activating the Card, you agree to be bound by these Terms and Conditions. All users of the Card must make themselves familiar with these Terms and Conditions which can be found on Waivpay’s Website <a href="https://www.waivpay.com/" target="_blank">www.waivpay.com</a></p>
        <ol start={1} className="list-outside">
          <li>By allowing the addition of the Card into your Pays-enabled Device, you acknowledge and agree to be bound by these Terms and Conditions. You also acknowledge that you have read and understood our Waivpay Privacy Policy which is available at <a href="https://www.waivpay.com/privacy/" target="_blank">www.waivpay.com/privacy</a></li>
        </ol>

        <h3>How the Card works and where you can use it</h3>
        <ol start={2} className="list-outside">
          <li>The Card is a prepaid Card that can be used for purchasing goods and services where the Card is accepted for electronic transactions (excluding transactions at ATMs or over the counter at financial institutions). The Card can be used as a credit Card but cannot be linked to any deposit account. You understand and agree that some merchants may not accept the Card.</li>
          <li>The Card cannot be used to make transactions that exceed the Card’s available balance.</li>
          <li>The Card is not reloadable and is subject to the limit of the amount initially loaded onto the Card. It is only valid and usable until the expiry date displayed. This is viewable alongside the Card. Upon expiry, the remaining available balance is forfeited. We are not required to give you any notice before this happens.</li>
          <li>The Card can only be used subject to the merchants’ terms and conditions.Authorisations may be declined at some merchants (such as gambling merchants or merchants who choose not to accept the Card). We are not liable in any way when authorisation is declined for a particular transaction, except where the authorisation has been declined because of an act or omission on our part or fraudulent transactions.</li>
        </ol>
        <h3>Fees</h3>
        <ol start={6} className="list-outside">
          <li>We will not charge any fees for using the Card. However, some merchants may charge you for using the Card and, where applicable, fees may be deducted from the balance of your Card at the time of the transaction.</li>
        </ol>

        <h3>Your Obligations</h3>
        <ol start={7} className="list-outside">
          <li>You are responsible for all transactions on the Card, except where there has been fraud or negligence by our employees or agents. If you notice any error relating to the Card, you should immediately notify Waivpay Ltd by sending an email to support@waivpay.com</li>
          <li>If you have a problem with a purchase made with the Card, or a dispute with a merchant, you must deal directly with the merchant involved. If you cannot resolve the dispute with the merchant, you can contact our customer support team by sending an email to support@waivpay.com</li>
          <li>You are responsible for checking your transaction history, knowing the available balance and date of expiry for the Card, all of which is available to you on your device and via our website www.waivpay.com</li>
        </ol>

        <h3>Liability</h3>
        <ol start={10} className="list-outside">
          <li>We are not liable in circumstances where you have other cards stored on your Device for payment and you inadvertently use a different Card when using your Device as a payment method. You must ensure that you select the correct card prior to using your Device as a payment method.</li>
          <li>
            We are not liable for:
            <ul>
              <li>the use, functionality or availability of a website, the availability of merchants who allow the use of the Card as payment, or a reduced level of service caused by the failure of third-party communication and network providers (except to the extent deemed liable under the ePayments Code); and/or</li>
              <li>temporary interruptions in service due to failure beyond the merchants control including, but not limited to, the failure of interconnecting operating systems, computer viruses, forces of nature, labour disputes and armed conflicts.</li>
            </ul>
          </li>
          <li>
            We are not liable for transactions on your Card if:
            <ul>
              <li>your Device security measures are compromised.</li>
              <li>where you allow others access to, or the means to access, your Device; or</li>
              <li>any other circumstances in which you allow others to circumvent the security measures of your Device.</li>
            </ul>
          </li>
          <li>
            To the extent permitted by law, we:
            <ul>
              <li>exclude all statutory or implied conditions and warranties and any other liability we may have to you (including liability for indirect or consequential loss) that may arise under statute or at law, including, without limitation for breach of contract, in tort (including negligence) or under any other cause of action howsoever arising out of, or in any way related to, the use of the Card and our goods and services;</li>
              <li>limit our liability under any condition or warranty which cannot be legally excluded in relation to the supply of goods or services to (at our election) replacing the goods or services or supplying equivalent goods or services again; and</li>
              <li>make no warranties or representations about our goods or services or the content related to our goods and services, including but not limited to warranties or representations; that such content will be complete, accurate or up-to-date or that access to the Card or related services will be uninterrupted, error-free, or free from viruses.</li>
            </ul>
          </li>
        </ol>

        <h3>Our Rights</h3>
        <ol start={14} className="list-outside">
          <li>We may restrict, halt or stop the use of the Card if suspicious activities or illegal and/or fraudulent activities are noticed.</li>
        </ol>

        <h3>Refunds</h3>
        <ol start={15} className="list-outside">
          <li>Any refunds on Card transactions are subject to the policy of the specific merchant. Funds cannot be returned to the card if a purchase needs to be returned or refunded. Every merchant will handle as per their own policies. The merchant may issue a cash refund or store credit if in-store or process the refund to another card. We are not responsible for returns or credits of any purchases made with the card.</li>
        </ol>

        <h3>Queries and Complaints</h3>
        <ol start={16} className="list-outside">
          <li>If you have a query about the Card, you should initially contact Waivpay at  the following: support@waivpay.com</li>
        </ol>

        <h3>Privacy and Information Collection</h3>
        <ol start={17} className="list-outside">
          <li>Information will be disclosed to third parties about the Card, or transactions or transactional activity made with the Card, whenever allowed by law, and also where necessary to operate the Card and process transactions. You should view the privacy policy on Waivpay’s website.</li>
        </ol>
        <p>Waivpay may collect your personal information:</p>
        <ul className="list-outside">
          <li>to identify you;</li>
          <li>to provide information about a product or service;</li>
          <li>to consider your request for a product or service;</li>
          <li>to provide you with a product or service;</li>
          <li>to assist in arrangements with other organisations in relation to the promotion and provision of a product or service or suspend its operation until it is provided;</li>
          <li>to perform administrative, reporting and operational tasks (including systems development and testing, staff training, and market or customer satisfaction research);</li>
          <li>to prevent or investigate any fraud or crime (or a suspected fraud or crime); and</li>
          <li>as required by relevant laws and scheme rules. </li>
        </ul>
        <p>Where you do not provide some or all of the information requested by Waivpay, we may be unable to provide you with a product or service.</p>
        <p>If you have concerns about how we collect, manage and/or disclose your personal information, you can read our Privacy Policy at www.waivpay.com/privacy</p>

        <h3>Providing your information to others</h3>
        <ol start={18} className="list-outside">
          <li>Waivpay may provide your information:
            <ul>
              <li>to another member of its group;</li>
              <li>to any outsourced service providers (for example mailing houses, data switch service companies);</li>
              <li>to regulatory bodies, government agencies, law enforcement bodies and courts;</li>
              <li>to other parties as is authorised or required by law; or</li>
              <li>to participants in the payments system and other financial institutions for the purpose of resolving disputes, errors or other matters arising out of your use of the Card or third parties using your Gift Card or Card information. </li>
            </ul>
          </li>
        </ol>

        <h3>Contact Us</h3>
        <ol start={19} className="list-outside">
          <li>Please contact us if you have any questions or comments about our privacy policies and procedures. We welcome your feedback.</li>
        </ol>

        <h3>Complaints</h3>
        <ol start={20} className="list-outside">
          <li>Additionally, if you wish to make a general complaint about our services, please contact us directly at support@waivpay.com addressing your complaint to the Complaints Officer. If you need to resolve an issue or make a complaint about how we collect, use or store your personal information, please contact us directly at support@waivpay.com addressing your complaint to the Privacy Officer.</li>
        </ol>
        <p>If you are not satisfied with our response with regards to your complaint about how we collect, use or store your personal information, you can contact the Office of the Australian Information Commissioner GPO Box 5218, Sydney NSW 2001</p>

        <div>
          <span className="block">(p) 1300 363 992</span>
          <span className="block">(e) enquiries@oaic.gov.au</span>
          <span className="block">(w) www.oaic.gov.au (where you can make a complaint online)</span>
        </div>



        <h3>Anti-Money Laundering and Counter Terrorism Financing, and its associated rules, regulatory guides and regulations. (“AML”)</h3>
        <ol start={21} className="list-outside">
          <li>
            You acknowledge and agree that:
            <ul>
              <li>where required, you will provide to Waivpay all information reasonably requested by Waivpay in order for Waivpay to comply with the fraud monitoring and anti-money laundering and counter terrorism financing obligations imposed on it pursuant to the AML Legislation;</li>
              <li>Where you do not provide Waivpay the information required above, Waivpay may refuse to provide You its services;</li>
              <li>Waivpay may be legally required to disclose information about you to regulatory and/or law enforcement agencies;</li>
              <li>Waivpay may block, delay, freeze or refuse any transactions for legitimate business, prudential or regulatory reasons, where Waivpay considers reasonable grounds exist to believe that the relevant transactions are fraudulent, in breach of the AML Legislation or any other relevant law or term within this agreement;</li>
              <li>where transactions are blocked, delayed, frozen or refused by Waivpay, you agree that Waivpay is not liable for any loss suffered by it or other third parties arising directly or indirectly as a result of Waivpay taking this action; and</li>
              <li>where required, Waivpay will monitor all transactions that arise pursuant to your use of the Card in accordance with its obligations imposed on it in accordance with the AML/CTF Legislation.</li>
            </ul>
          </li>
        </ol>


        <h3>Changes to Terms and Conditions</h3>
        <ol start={22} className="list-outside">
          <li>We may change or add to these Terms and Conditions at any time and any changes to these Terms and Conditions can be viewed on the website. By continuing to use the website and/or our services you will be deemed to accept any variation of these terms. We will make any changes in accordance with any applicable legislation and industry codes. If we believe a change is unfavourable to you, we will usually give you 7 days' notice or no notice if the change is in accordance with law and industry codes.</li>
        </ol>

        <h3>Applicable law</h3>
        <ol start={23} className="list-outside">
          <li>These Conditions of Use are to be construed and enforced in accordance with the laws of NSW, Australia. Any dispute arising from your receipt or use of a Card is exclusively subject to the jurisdiction of the courts of NSW, Australia.</li>
        </ol>

        <h3>Reasonable Use</h3>
        <ol start={24} className="list-outside">
          <li>You agree to access and use Waivpay in accordance with our requirement that your use be reasonable. In particular, by accessing Waivpay, you agree and acknowledge that:
            <ol type={'a'}>
              <li>all access to our services is subject to reasonable use; and</li>
              <li>where we think your usage is unreasonable, we may suspend or refuse access to the services and your account for legitimate business, prudential or regulatory reasons.</li>
            </ol>
          </li>
        </ol>
        <p>We aim to provide quality service to all our clients at all times. The reasonable use requirement is designed to ensure that no customer is unfairly disadvantaged by the behaviour of other customers. If at any time we think a customer’s usage may disadvantage other clients, we may suspend, restrict or refuse access to our services.</p>

        <h2>Section 2 - Terms of Use</h2>
        <h3>Introduction</h3>
        <ol start={1} className="list-outside">
          <li>This website and mobile app is operated by or on behalf of Waivpay Ltd, ABN 29 630 927 105 and its related bodies corporate (together “We, Us, Our and Waivpay’”).</li>
        </ol>

        <h3>Acceptance</h3>
        <ol start={2} className="list-outside">
          <li>By visiting or using www.waivpay.com you acknowledge and agree with the website terms of use. You must only use this website for the purposes for which it was designed and offered and not for any other purpose. By using this website, you agree to use it in a responsible manner, taking into account the effects your use of this website may have on other users. Examples of acceptable use would include browsing the various pages and contacting the site owner. Examples of inappropriate use would include planting malware, viruses or, using the site to attempt to spread spam or other unnecessary content.</li>
        </ol>

        <h3>User and Intellectual Property Rights</h3>
        <ol start={3} className="list-outside">
          <li>You are provided with access to it only for your personal and non-commercial use. Other than for these permitted purposes, and for the purposes of and subject to the conditions prescribed under statutes that apply in your location, you may not, in any form or by any means:
            <ul>
              <li>adapt, reproduce, store, distribute, transmit, print, display, perform, publish or create derivative works from any part of this website; or</li>
              <li>commercialise any information, products or services obtained from any part of this website, without our written permission.</li>
            </ul>
          </li>
          <li>All rights in this website and the content on this website including copyright, design rights, patents, inventions, knowhow, database rights, trademarks, source codes and any other intellectual property rights in any of the foregoing are reserved to Waivpay and/or their content and technology providers. All trade names, trademarks, service marks and other product and service names and logos (the “Marks”) displayed on the website are proprietary to their respective owners and are protected by applicable trademark and copyright laws. These Marks may be registered or unregistered marks of Waivpay. Apple Pay is a trademark of Apple Inc., registered in the U.S. and other countries and regions.</li>
          <li>Waivpay is a registered trademark. Copyright in the information contained in this site subsists under the Copyright Act 1968 (Cth) and, through international treaties, the laws of many other countries. Except as permitted under the Copyright Act 1968 (Cth) or other applicable laws, no part of this publication may be otherwise reproduced, adapted, performed in public or transmitted in any form by any process (graphic, electronic or mechanical, including photocopying, recording, taping or by storage in an information retrieval system) without the specific written consent of Waivpay. Nothing contained on the website should be construed as granting any licence or right of use of any other person’s or entity’s trademark which is displayed on this website without their express permission. You may not remove, change or obscure the Waivpay logo or any notices of proprietary rights on any content of this website.</li>
        </ol>

        <h3>Disclaimer and liability</h3>
        <ol start={6} className="list-outside">
          <li>The content on this website (including any publications) is intended only to provide a summary and general overview on matters of interest. It is not intended to be comprehensive nor does it constitute financial or legal advice. You should seek legal or other professional advice before acting or relying on any of the content. Waivpay does not warrant or represent that the information in this site is free from errors or omissions or is suitable for your intended use. Subject to any terms implied by law and which cannot be excluded, Waivpay accepts no responsibility for any loss, damage, cost or expense (whether direct or indirect) incurred by you as a result of any error, omission or misrepresentation in any information in this site. Information provided on Waivpay as to future matters are not guaranteed to be accurate and any statements as to past performance do not represent future performance. Words implying natural persons include partnerships, incorporated bodies, associations and public authorities. All fees and charges are inclusive of GST, unless otherwise stated.</li>
        </ol>

        <h3>Links and Subscriptions</h3>
        <ol start={7} className="list-outside">
          <li>Any information provided by third party data suppliers has not been verified by Waivpay. Waivpay does not make any representation or warranty as to the timeliness, reliability, accuracy or completeness of the material, nor does it accept any responsibility arising in any way for errors in, or omissions from that material. Your use of any link to a linked website is entirely at your own risk. This website may contain links to other websites solely for your convenience only and may not remain current or be maintained. We do not endorse, recommend or approve of any information, products or services referred to on such linked sites and assumes no responsibility for the contents of any other website to which this website offers links. Where you have received a message via email, the message is intended only for the addressee and contains information which may be confidential. Where you download or print copies of any information contained in this email, you acknowledge that it is for your personal and private use, and that it may not be reproduced, republished, broadcast or otherwise distributed without Waivpay’s prior written consent.</li>
        </ol>

        <h3>Personal Logins and Restricted Areas</h3>
        <ol start={8} className="list-outside">
          <li>We reserve the right to implement areas which allow users to login in and use to access personal materials. By creating a login, you are responsible for the security of your login information. Any restricted areas must not be accessed without permission or authorisation. If your account is compromised as a result of failure to secure your login details, we are not liable for any losses you sustain as a result.</li>
        </ol>

        <h3>Cookies and Privacy Policy</h3>
        <ol start={9} className="list-outside">
          <li>These website terms of use incorporate, and should be read together with, our Privacy Policy. Waivpay will use only the personal data which you submit to us via the website (and any other means to set up your account) in accordance with the Waivpay Privacy Policy. The Waivpay Privacy Policy also describes how this website may collect, store and use cookies. We will not disclose any private information provided by you through our website except where required by law. For more information on this please refer to our Privacy Policy.</li>
        </ol>
      </div>
    </div>
  </div>
);

export default Digital;
