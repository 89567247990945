import React from 'react';
import Drawer from "@components/menuBar/Drawer";
import Nav from "@components/menuBar/Nav";
import Logo from "@components/menuBar/Logo";
import NavButtons from "@components/menuBar/NavButtons";

const MenuBar = () => (
  <div className="relative w-full bg-white">
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="flex w-full justify-between md:justify-end items-center md:space-x-10 border-0 py-6">
        <Logo/>
        <Nav/>
        <NavButtons className="block lg:hidden xl:block"/>
        <Drawer/>
      </div>
    </div>
  </div>
);

export default MenuBar;
